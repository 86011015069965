import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';
import styled, { ThemeProvider } from 'styled-components';
import { light, dark } from 'themes';
import breakpoints from 'utils/breakpoints';

import { HEADER_HEIGHT } from 'components/Header';
import Layout from 'components/Layout';
import Heading from 'components/Heading';
import { graphql } from 'gatsby';

const Wrapper = styled.div`
  background-image: linear-gradient(180deg, #ddeeff 0%, #ffffff 30%);
`;

const Intro = styled.section`
  padding-top: ${HEADER_HEIGHT + 52}px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${HEADER_HEIGHT + 90}px;
    padding-bottom: 60px;
    h3 {
      text-align: left;
    }
  }
`;

const Body = styled.main`
  color: #1c2f45;
  padding-bottom: 150px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 30px;
    font-family: Helvetica;
  }

  p {
    margin: 0 0 27px;
    font-size: 16px;
    line-height: 28px;
  }

  a:visited,
  a {
    color: #0276f1;
  }
`;

function PrivacyPage({ data }) {
  const content = data.markdownRemark.html;
  const { title } = data.markdownRemark.frontmatter;

  return (
    <ThemeProvider theme={light}>
      <Layout fullSize>
        <Wrapper>
          <ThemeProvider theme={dark}>
            <Container>
              <Row justify="center">
                <Col xs={10} lg={8}>
                  <Intro>
                    <Heading title={title} />
                  </Intro>
                  <Body dangerouslySetInnerHTML={{ __html: content }} />
                </Col>
              </Row>
            </Container>
          </ThemeProvider>
        </Wrapper>
      </Layout>
    </ThemeProvider>
  );
}

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      html: PropTypes.string,
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
      }
      html
    }
  }
`;

export default PrivacyPage;
